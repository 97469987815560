<template lang="pug">
.CurrentUser.d-flex.justify-content-between.alig-items-stretch(:style="isAtril && `width: 100%; overflow: hidden; height: calc(100vh - 112.06px) !important;`").h-100
  .Content.w-100.flex-1
    .banderaSvg
    div.box-dk.mt-4.mb-4.pt-4.text-center
      h3.fonterH3.mx-auto.d-block Ingresa tus datos
      p.fonterCurrentUser.textHero.m-0.mx-auto.d-block Digita tu número de documento
    form#validateUser(
      @submit.prevent="sendData"
      autocomplete="off"
      :style="isAtril ? 'margin-top: 30px;' : 'padding-bottom: 100px;'"
    )
      .containerForm
        p.label-text Tipo de documento
        .form-select-container.select--Input-text.mb-2
          b-form-select.Input-text(
            v-model="models.TipoIdentificacion",
            :options="options",
            size="sm",
            autocomplete="off",
            required,
          )#formSelect
          svg(@click="() => document.getElementById('formSelect').focus()").mr-3(width='30' height='29' viewbox='0 0 30 29' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M15.0001 1.16699C17.6371 1.16699 20.215 1.92946 22.4077 3.35791C24.6003 4.78637 26.3093 6.81667 27.3184 9.19211C28.3276 11.5675 28.5916 14.1814 28.0772 16.7031C27.5627 19.2249 26.2928 21.5413 24.4281 23.3594C22.5634 25.1774 20.1877 26.4156 17.6013 26.9172C15.0149 27.4188 12.334 27.1613 9.89762 26.1774C7.46128 25.1935 5.3789 23.5272 3.91382 21.3894C2.44873 19.2516 1.66675 16.7382 1.66675 14.167C1.66675 10.7192 3.0715 7.41259 5.57198 4.97462C8.07246 2.53665 11.4638 1.16699 15.0001 1.16699Z' stroke='#788591' stroke-width='2.025' stroke-linecap='round' stroke-linejoin='round')
            path(d='M15.0002 16.7656L18.5624 13.2925' stroke='#788591' stroke-width='2.025' stroke-linecap='round' stroke-linejoin='round')
            path(d='M11.4377 13.2925L14.9999 16.7656' stroke='#788591' stroke-width='2.025' stroke-linecap='round' stroke-linejoin='round')
          .svgBackground
        p.label-text Número
        input.Input-text(
          style="margin-bottom: 15px !important;"
          type="text",
          v-model="models.NumeroIdentificacion",
          min="0"
          placeholder="Ej: 105456783",
          required,
          autocomplete="off",
          :disabled="isAtril"
        )#cedula_number
    .d-flex.justify-content-center.mt-5.mb-2(v-if="isAtril")
      PanelAlpha(v-if="isAlpha").mr-5
        .item-panel.bigbutton(
          v-for="(panelAlphaItem, index) in panelAlpha"
          :key="index"
          @click="printPanel(panelAlphaItem.toUpperCase())"
        )
          | {{ panelAlphaItem.toUpperCase() }}
      Panel
        .item-panel.bigbutton(@click="printPanel(1)") 1
        .item-panel.bigbutton(@click="printPanel(2)") 2
        .item-panel.bigbutton(@click="printPanel(3)") 3
        .item-panel.bigbutton(@click="printPanel(4)") 4
        .item-panel.bigbutton(@click="printPanel(5)") 5
        //- 347px
        .item-panel.bigbutton(@click="printPanel(6)") 6
        .item-panel.bigbutton(@click="printPanel(7)") 7
        .item-panel.bigbutton(@click="printPanel(8)") 8
        .item-panel.bigbutton(@click="printPanel(9)") 9
        .item-panel.bigbutton(@click="onChangeAlpha").buttonAbc: strong {{ !isAlpha ? "ABC" : "123" }}
        .item-panel.bigbutton(@click="printPanel(0)") 0
        .item-panel.bigbutton.item-panel-delete_button(@click="printPanel('<')")
          span Borrar
    .containerForm
      button(
        :class="!models.TipoIdentificacion || !models.NumeroIdentificacion || charging ? 'disabled' : 'active'"
        @click="printPanel('Ok')"
        :disabled="!models.NumeroIdentificacion"
      ).text-center.buttonService.mt-3
        span(v-if="!!!charging")
          | Continuar
        div(v-else)
          MiniSpinner
    .error(v-if="error")
      span {{error.message}}
  .box.box2.mx-0.w-100.h-100.position-relative
    img(v-if="areaCustom.backgrounds" :src="areaCustom.backgrounds.background1" alt="saludImportantImage").containerImg-img.w-100
    img(src="@/assets/logoFSFBTransparent.png" alt="logoFSFBTransparent").logoFSFBTransparent
    .box-tape.text-center.d-flex.pl-5
      .boxText.text-start.d-block
        .ml-4.pl-3.boxBorderer
          h3.welcomeText.mb-0: strong {{areaCustom.text}}
          h4.welcomeText.welcomeTextSecondary.mb-2: strong (Zona {{areaCustom.label}})
        p.descText.mb-0.pl-5.ml-2.mt-4(v-if="areaCustom && areaCustom.labelEnglish") Welcome to {{areaCustom.labelEnglish}}
        .boxText.text-start.d-block(v-if="areaCustom && areaCustom.extraLabel")
        .ml-4.pl-3
          h4.welcomeText.welcomeTextSecondary.mb-2.ml-3.mt-2: strong {{areaCustom.extraLabel}}
        //- p.descText.mb-4.pl-5.ml-2 Diagnostic Imaging
      .box-label.text-center.d-flex.pl-5(v-if="areaCustom && areaCustom.homeLabel")
        .ml-4.pl-3.boxBorderer
          h4.welcomeText.welcomeTextSecondary.mb-2: strong {{areaCustom.homeLabel}}

</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";
import Panel from "./components/Panel";
import PanelAlpha from "./components/PanelAlpha";
import Atras from "mdi-vue/ArrowLeftBold";
// import LS from "../../helpers/localStorage";

export default {
  name: "Home",

  components: {
    Header,
    Continue,
    MiniSpinner,
    Panel,
    Atras,
    PanelAlpha
  },

  created() {
    // clear error message
    this.clearError();

    // control timer on atril
    if (this.isAtril) {
      // this.$store.dispatch("setTimer");
      this.cleanCurrentUser();

      // console.log(LS.getItem("scanCed"));
      // Scanned Identification number
    //   if (LS.getItem("scanCed")) {
    //     this.models.NumeroIdentificacion = LS.getItem("scanCed");
    //     LS.setItem("scanCed", null);
    //   }
    }
  },

  props: ["onStopTimer"],

  data: () => ({
    myForm: "",
    captchaElement: null,
    isCapcha: true,
    models: {
      NumeroIdentificacion: "",
      TipoIdentificacion: "CC"
    },
    isAlpha: false,
    panelAlpha: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z"
    ]
  }),

  // watch: {
  //   "models.TipoIdentificacion"(val) {
  //     if (val) {
  //       if (this.isAtril) {
  //         this.$store.dispatch("setTimer");
  //       }
  //     }
  //   }
  // },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrowFSFB.currentSite,
      options: state => state.virtualrowFSFB.tipide,
      currentUser: state => state.virtualrowFSFB.currentUser,
      citas: state => state.virtualrowFSFB.citas,
      charging: state => state.virtualrowFSFB.charging,
      isAtril: state => state.virtualrowFSFB.isAtril,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      secondColorBlue: state => state.virtualrowFSFB.secondColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      error: state => state.virtualrowFSFB.error,
      sites: state => state.virtualrowFSFB.sites,
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    toSend() {
      return { ...this.models, CodigoOficina: this.$route.params.code };
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    newUserReady() {
      return !!(
        this.models.NumeroIdentificacion && this.models.TipoIdentificacion
      );
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    demoValidation() {
      return true;
    },

    areaCustom() {
      try {
        let sites = this.sites.filter(s=>s.code == this.$route.params.code)
        return sites[0].customAreas[this.$route.query.area]
      } catch (error) {
        return "";
      }
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrowFSFB/goToView",
      goBack: "virtualrowFSFB/goBack",
      authenticate: "virtualrowFSFB/authenticate",
      cleanCurrentUser: "virtualrowFSFB/cleanCurrentUser",
      removeTimer: "removeTimer",
      // setTimer: "setTimer",
      sendTracking: "virtualrowFSFB/handleTracking",
      clearError: "virtualrowFSFB/clearError",
    }),
    continueWithExistent() {
      // if (this.isAtril) this.$store.dispatch("setTimer");
      this.models.TipoIdentificacion = this.currentUser.TipoIdentificacion;
      this.models.NumeroIdentificacion = this.currentUser.NumeroIdentificacion;
      this.sendData();
    },
    printPanel(key) {
      const $cedula_number = document.getElementById("cedula_number");
      if ($cedula_number) {
        $cedula_number.focus();
      }

      // if (this.isAtril) {
      //   this.$store.dispatch("setTimer");
      // }
      if (key === "<") {
        this.models.NumeroIdentificacion = this.models.NumeroIdentificacion.slice(
          0,
          -1
        );
        return;
      }
      if (key == "Ok") {
        if (
          !this.models.NumeroIdentificacion ||
          !this.models.TipoIdentificacion
        )
          return;
        return this.sendData();
      }
      this.models.NumeroIdentificacion += key;
    },
    onChangeAlpha() {
      this.isAlpha = !this.isAlpha;
    },
    async sendData() {
      this.onStopTimer();
      document.getElementById("cedula_number")?.blur();
      if (this.isAtril) await this.removeTimer();
      await this.authenticate(this.toSend);
      // if (this.isAtril) await this.setTimer();
      // if (this.demoValidation) this.goToView({ view: "ExistentUser" });

      // tracking
      setTimeout(() => {
        let origin = "fv_";
        if (location.hash.includes("fv_atril")) origin = "fv_atril_fsfb_";
        let auth = this.error
          ? " autentitacion-no-cliente"
          : "autenticacion-cliente";
        let trackingData = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: "",
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 2000);
    }
  }
};
</script>

<style scoped>
.Content {
  position: relative;
  width: 100%;
}

.CurrentUser {
  margin: auto;
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.box-dk {
  margin: auto;
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 12300000;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 16px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  /* color: #fff; */
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

#d-flex span svg {
  width: 20px;
  height: 20px;
  /* fill: white; */
}

.big_box {
  position: relative;
}

.dk-link {
  outline: none;
  border: none;
  appearance: none;
  text-decoration: none;
  background: none;
  /* color: #6565; */
  color: #6558f5;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin: 93px auto;
}

.dk-link:hover {
  text-decoration: underline;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.disabled {
  cursor: not-allowed;
  background: #0058a0a4;
}

.disabled:hover {
  background: #0058a0cb;
}

.dk-pos-dk {
  position: absolute;
  top: 30%;
  left: 0px;
  font-weight: bold;
  transition: 1s;
  right: 0px;
}

.Input-text::placeholder {
  color: rgb(0 0 0) !important;
}

.form-select-container {
  position: relative;
}

select.Input-text {
  background: none !important;
}

.select--Input-text .svgBackground {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 20px !important;
}

.form-select-container svg {
  background: #ffffff;
  right: 13px;
  position: absolute;
  z-index: -2;
  top: 50%;
  transform: translateY(-50%);
}

.Input-text {
  background: #ffffff !important;
  border: 2.7px solid #dedede !important;
  border-radius: 20px !important;
  box-sizing: border-box;
  font-size: 24px;
  padding: 1rem 2rem;
}

.h1 {
  font-size: 2.8rem;
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.text-start {
  text-align: start;
}

.buttonService:disabled {
  background-color: #f2f2f2;
  color: #dedede;
  filter: none;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.label-text {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: #5ac3f0;
}

.containerImg-img {
  object-fit: cover;
  max-height: calc(100vh - 112.06px);
}

.box-tape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    359.49deg,
    #202d78 2.94%,
    rgba(32, 40, 87, 0.661458) 56.73%,
    rgba(33, 30, 23, 0) 99.46%
  );
}

.flex-1 {
  flex: 1;
}

.logoFSFBTransparent {
  position: absolute;
  right: 0;
  bottom: 126px;
  z-index: 2;
}

.containerForm {
  max-width: 476px;
  margin: auto;
  display: block;
}

.fonterCurrentUser {
  max-width: 476px;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

.boxText {
  position: absolute;
  bottom: 163px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  max-width: fit-content;
}

.welcomeText {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 55px;
  color: #ffffff;
}

.boxBorderer {
  border-left: 16px solid #22bdff;
}

.welcomeTextSecondary {
  font-size: 40px;
  line-height: 40px;
}

.box.box2 {
  padding: 0;
  max-width: 799px;
}

.descText,
.consultasText {
  font-size: 24px;
  color: #ffffff;
}

.descText {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

.copyrightText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.consultasText {
  max-width: 497px;
}

.Input-text::placeholder {
  color: #dedede !important;
}

.buttonAbc strong {
  color: #202d78;
}

.error {
  color: #e66921;
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

.box-label{
  position: absolute;
  top: 8%;
  right: 5%;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
