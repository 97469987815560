<template lang="pug">
.Container.d-flex.align-items-center.justify-content-start(
  :class="!!!turn ? 'container-logo pb-1' : 'dk-padd pb-1'"
  :style="isAtril ? 'color: #545454; height: calc(100vh - 176px); display: flex; flex-direction: column; justify-content: center;' : 'display: flex; flex-direction: column; justify-content: center; font-size: 1.3rem; font-weight: bold; height: calc(100vh - 102.9px);'"
)
  .pt-4.mt-2
  .card-box-white.mb-4.littleBox.pb-4(style="height: auto;")
    figure.containerImage.position-relative(v-if="stage > 0")
      img(src="@/assets/Card.png" alt="Card").Card-img
      .boxImgBGTop
        h4.fonterAssignedLabel Te hemos dado un
        h3(v-if="priority").priorityText.fonterAssigned Turno preferencial
        h3(v-else).fonterAssigned Turno general
    p.specialRad.d-flex.justify-content-center.align-items-center.m-0.mt-0.pt-0.pb-0.px-1
      span.serviceWeight Servicio:
      strong.radiologyText.pl-3 {{ service }}
  .Content.card-box-white.pt-3.px-5.pb-4
    audio#attention(@ended="soundEmmited")
      source(src="@/assets/sounds/campanitas.mp3" type="audio/mpeg")
    audio#aproximate
      source(src="@/assets/sounds/turno_audio.mp3" type="audio/mpeg")
    div(
      v-if="stage === -1"
      style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
    )
      div( style="text-align: center;" v-if="errorCounter < 3")
        Loading
        h3 Conectando...
      span(style="text-align: center" v-else)
        button.btn.btn-info(
          @click="startTurn"
          style="font-style: normal; font-weight: 600; font-size: 25.9817px; line-height: 34px; text-align: center; color: #f9f9f9;"
        ) Reintentar
      span(style="text-align: center" v-if="errorCounter > 2")
        br 
        br
        button.btn.btn-info(
          @click="reload"
          style="font-style: normal; font-weight: 600; font-size: 25.9817px; line-height: 34px; text-align: center; color: #f9f9f9;"
        ) Volver
      h5.mt-3(
        v-if="errorCounter >= 3"
        style="font-style: normal; font-weight: 600; font-size: 25.9817px; line-height: 34px; text-align: center; color: #d3455b;"
      ) Error de conexión con servidor. Presiona el botón para reintentar..

    .position-relative.d-inline.text-center(v-if="stage === 0")
      img(:src="Conected" class="size_icon mx-auto")
      slot(name="enqueue")
      h5(
        style="font-style: normal; font-weight: 600; font-size: 25.9817px; line-height: 34px; text-align: center; color: #283645;"
      ) Estamos solicitando tu turno. Por favor espera.

    div(v-if="stage === 1")
      slot(name="waiting" v-bind:turn="turnCommon")
      template(v-if="!onlyGenerate")
        .mt-3
          b-form-checkbox.audioNotification(
            :class="{'isDisabled': !audioNotification, 'isEnabled': audioNotification}"
            id="checkbox-1"
            v-model="audioNotification"
            name="checkbox-1"
          ) Notificarme con audio cuando sea atendido
        p.mt-2(v-if="!hideVideo") Mientras esperas:
        div(v-if="!hideVideo")
          video(
            style="max-width: 350px; margin: auto; display: block;"
            id="video"
            :src="videoSource"
            :poster="videoPoster"
            playsinline
            controls
          )
          p.mt-2 Conoce nuestro Autoservicio Digital
        div(class="div_center_button" :style="hideVideo ? `margin-top: 2rem;` : ``")
          .mb-4.color-colmedica-light(
            v-if="status === 'ABSENT'"
          ) El turno fue marcado como 'Ausente'. Debe dirigirse a un asesor para que puedan volver a llamarlo.
          u(@click="showModal = true" style="cursor: pointer;").pb-5.modal_button.cancelTurnDialog.color-colmedica-light Cancelar Solicitud
      b-modal(v-model="showModal" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="p-2 position-relative w-auto h-auto")
          div(class="d-block text-center modal_inside" )
            p(class="my-4 modal_highlight_text") Estás a punto de cancelar la solicitud
            p(class="my-4") Esto cancelará el proceso de atención con el asesor {{ comp }}
            p(class="my-4") ¿Qué deseas hacer?
          div(class="position-relative")
            b-button(@click="cancelTurnAction()" variant="outline-danger" class="btn-block cancelTurnConfirmation") Cancelar solicitud
            b-button(@click="showModal = false" variant="bluecolmedica" :style="colorComputed" class="btn-block") Continuar con el asesor {{ comp }}
    div(v-if="stage === 2")
      img(:src="Altavoz" alt="altavoz" v-if="!siteIsOffice" style="max-width: 66px; margin: 25px 0;")
      img(src="@/assets/bell.png" alt="bell" v-else)
      h5.mt-4.text-middle.color-colmedica-gray(
        :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : 'font-weight: bold !important; color: #545454;'"
      ) Tu número
      h4.text-middle.color-colmedica-light.font-weight-bold(
        :style="isAtril ? 'font-size: 2rem; !important; font-weight: bold;' : ' font-weight: bold !important; margin-bottom: 0;'"
      ) {{ myTurn }}
      h5.mt-4.text-middle.color-colmedica-gray(
        :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
      ) Ha sido llamado
      //- ********** Modificacion temporal mientras se logra obtener el nombre del puesto de api mobile
      template(v-if="attentionModule")
        h5.mt-4.text-middle.color-colmedica-gray.font-weight-bold(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;': 'font-weight: bold !important; color: rgb(84, 84, 84); max-width: 187px; margin: 15px auto; font-size: 1.3rem; line-height: 25px;'"
        ) Por favor acércate al módulo de atención
        h4.text-middle.color-colmedica-light.font-weight-bold {{ attentionModule }}
        h5.mt-4.text-middle.color-colmedica-light(
          style="font-weight: bold !important; color: #545454;"
        ) Serás atendido por
          h4.ml-2.color-colmedica-light.mt-1
            strong {{ attentionAgent }}
      template(v-else)
        h5.mt-4.text-middle(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;': ' font-weight: bold !important; color: #545454;'"
        ) Por favor procede al módulo que se indica en la pantalla de TV

    div(v-if="stage === 3")
      h5.mt-4.text-middle.color-colmedica-gray(
        :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
      ) Tu turno ha finalizado.
      h5.mt-4.text-middle.color-colmedica-gray(
        :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
      ) Te esperamos en una próxima ocasión.
      slot(name="endInfo")
        h6.mt-4.text-middle.color-colmedica-gray(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
        ) Ya puedes cerrar esta ventana
  .buttons.Footer(:style="colorSecondaryComputed" v-if="stage === 1")
    .d-flex.justify-content-end
      slot(name="waitingActions")
  .Footer(v-if="stage !== 1 || isAtril")
</template>

<script>
import { mapActions, mapState } from "vuex";
import LogoColmedica from "@/assets/colmedica_logo.png";
import Conected from "@/assets/conected.gif";
import Altavoz from "@/assets/altavoz.png";
import Loading from "./Loading.vue"


export default {
  name: "Turno",

  components: {
    Loading
  },

  data: () => ({
    stage: 0,
    LogoColmedica,
    Conected,
    Altavoz,
    errorCounter: 0,
    audioNotification: false,
    showModal: false,
    timerSound: null,
    soundEnded: false
  }),

  created() {
    this.startTurn();

    setTimeout(() => {
      let origin = "fv_";
      if (location.hash.includes("fv_atril")) origin = "fv_atril_fsfb_";
      // tracking
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-turno-asignado",
        debmedia_turn_code: this.turn?.code,
        url_origen: window.location.href,
        origen: origin + this.currentSite?.type,
        mensajes: ""
      };

      this.sendTracking(trackingData);
    }, 1000);
  },

  props: {
    hideVideo: {
      type: Boolean,
      default: false
    },
    waitVideoSource: {
      type: String
    },
    waitVideoPoster: {
      type: String
    },
    hasAuthorize: {
      type: Boolean,
      default: false
    },
    authorize: {
      type: Boolean,
      default: false
    },
    onStopTimer: {
      type: Function
    },
    onlyGenerate: {
      type: Boolean,
      default: false
    },
    tramiteName: {
      type: String,
      required: true
    },
    coorelation_key: {
      type: String
    },
    userInfo: {
      required: true
    },
    branchId: {
      type: String
    },
    queueId: {
      type: String
    },
    source: {
      type: String,
      required: true
    },
    withAnalitycExtraFields: {
      type: Boolean,
      default: false
    },
    withVideocall: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    async actualTurn(val) {
      // if (!this.audioNotification && val != "canceled") {
      document.getElementById("aproximate").currentTime = 0;
      document.getElementById("aproximate").play();
      // }
    },

    stage(val) {
      if (val === 0) this.onStopTimer();
      if (val === 3) this.$emit("endTurn");
    },

    status(v) {
      this.turnPosition();

      // tracking
      setTimeout(() => {
        let origin = "fv_";
        if (location.hash.includes("fv_atril")) origin = "fv_atril_fsfb_";
        let trackingData = {
          uuid: window.person.id,
          accion: "customer-cambio-status: " + v,
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 1000);
    }
  },

  computed: {
    ...mapState({
      turn: state => state.turns.info,
      isAtril: state => state.virtualrow.isAtril,
      env: state => state.env,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      currentSite: state => state.virtualrow.currentSite,
      priority: state => state.virtualrowFSFB.priority,
      selectedAccionFSB: state => state.virtualrowFSFB.selectedAccion,
      selectedAccion: state => state.virtualrow.selectedAccion,
      menuFSB: state => state.virtualrowFSFB.menu,
      menu: state => state.virtualrow.menu
    }),

    comp() {
      const comp = this.env.VUE_APP_TEXT_COMPANY || this.env.VUE_APP_COMPANY;
      return comp.charAt(0).toUpperCase() + comp.slice(1);
    },

    videoSource() {
      return (
        this.waitVideoSource ||
        "https://bsix.assets.gobrilliant.com/colmedica/Colmedica_Promo_Video.mp4"
      );
    },

    videoPoster() {
      return (
        this.waitVideoPoster ||
        "https://bsix.assets.gobrilliant.com/colmedica/VideoColmedicaPoster.jpg"
      );
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    infoOfTurn() {
      return {
        onlyGenerate: this.onlyGenerate,
        source: this.source,
        queueId: this.queueId,
        branchId: this.branchId,
        coorelation_key: this.coorelation_key,
        withVideocall: this.withVideocall,
        withAnalitycExtraFields: this.withAnalitycExtraFields,
        withAuthorize: {
          authorize: this.authorize,
          hasAuthorize: this.hasAuthorize
        },
        params: this.userInfo
      };
    },

    turnCommon() {
      return {
        myTurn: this.myTurn,
        actualTurn: this.actualTurn,
        tramiteName: this.tramiteName
      };
    },

    status() {
      return this.turn?.status;
    },

    actualTurn() {
      return this.turn?.jsonDetails?.actualTurn;
    },

    myTurn() {
      return this.turn?.jsonDetails?.turn;
    },

    attentionModule() {
      return this.turn.worker?.name;
    },

    attentionAgent() {
      return `${this.turn.worker?.actualUser?.uUser?.firstName || ""} ${this
        .turn.worker?.actualUser?.uUser?.lastName || ""}`.trim();
    },

    service() {
      let serv =  this.menuFSB.find(m =>{
        if(m.actionCode == this.selectedAccionFSB.actionCode){
          return m;
        }
        if(m.children){
          return m.children.find(c=>c.actionCode == this.selectedAccionFSB.actionCode);
        }
      });
      if(serv && serv.children) serv = serv.children.find(s=> s.actionCode == this.selectedAccionFSB.actionCode);
      return serv?.service || serv?.label;
    }
  },

  methods: {
    ...mapActions({
      getTurn: "turns/getTurn",
      cancelTurnAction: "turns/cancelTurnAction",
      sendTracking: "virtualrow/handleTracking",
      removeTimer: "removeTimer",
      mobileNotification: "virtualrowFSFB/mobileNotification",
      goToView: "virtualrowFSFB/goToView",
    }),
    cancelTurnHandler() {
      this.$emit("goBack");
    },
    removeAllTimers(){
      var maxId = setTimeout(function(){}, 0);

      for(var i=0; i <= maxId; i+=1) { 
          clearTimeout(i);
      }
      this.removeTimer();
    },
    async startTurn() {
      this.stage = 0;
      this.onStopTimer();
      this.removeAllTimers();
      if (this.errorCounter >= 3) this.errorCounter = 0;
      return this.getTurn(this.infoOfTurn)
        .then(turnInfo => {
          this.turnPosition();
          this.$store.commit("virtualrowFSFB/setState", {
            key: "stopTimerCircle",
            value: true
          });
          if (this.onlyGenerate)
            this.$emit("generatedTurn", {
              turnInfo,
              generationInfo: this.infoOfTurn
            });
          setTimeout(() => {
            this.stage = 0;
            this.errorCounter = 0;
            this.audioNotification = false;
            this.showModal = false;
            this.timerSound = null;
            this.soundEnded = false;
            this.goToView({ view: "Home" });
          }, 3000);
        })
        .catch(error => {
          this.$store.commit("virtualrowFSFB/setState", {
            key: "stopTimerCircle",
            value: true
          });
          this.stage = -1;
          this.removeTimer();
          this.$emit("disableProgress");
          this.errorCounter++;
          console.error("Cant get turn for turn component", error);
          if (this.errorCounter < 3) setTimeout(() => this.startTurn(), 5000);
          else {
            // this.$store.dispatch("setTimer");
            this.$emit("enablePogress");
          }
        });
    },

    async turnAudioPlay() {
      if (this.audioNotification) {
        if (document.getElementById("attention")) {
          try {
            this.timerSound = setTimeout(() => {
              try {
                if (
                  document.getElementById("attention") &&
                  document.getElementById("attention").currentTime > 0
                )
                  return;
              } catch (error) {
                console.error("Invalid turn audio state", error);
              }
              this.soundEmmited();
            }, 2000);
            document.getElementById("attention").currentTime = 0;
            document.getElementById("attention").play();
          } catch (error) {
            console.error("Sound of meeting cant play in turn component");
            this.soundEmmited();
          }
        } else {
          console.error("Sound tag not found", this.$refs);
          this.soundEmmited();
        }
      } else this.soundEmmited();
      
      //  send FSFB notification 
      this.mobileNotification({
        title: "Turno Fundación Santa Fe",
        body: `Tu turno ${this.turn?.jsonDetails?.actualTurn} ha sido llamado.`,
        type: 1
      });
    },

    soundEmmited() {
      this.stage = 2;
    },

    turnPosition() {
      if (this.turn) {
        if (this.stage <= 0) {
          if (this.status === "WAITING_TO_BE_CALLED") this.stage = 1;
          else if (this.status === "ANNOUNCED") this.turnAudioPlay();
          else this.stage = 3;
        } else if (this.stage === 1) {
          if (this.status === "ANNOUNCED") this.turnAudioPlay();
          else if (this.status !== "WAITING_TO_BE_CALLED") this.stage = 3;
        } else if (this.stage === 2) {
          if (
            this.status === "WAITING_TO_BE_CALLED" ||
            this.status === "ABSENT"
          )
            this.stage = 1;
          else if (this.status !== "ANNOUNCED") this.stage = 3;
        }
      } else {
        if (this.stage > 0) this.stage = 3;
      }
    },

    reload(){
      location.reload()
    }
  }
};
</script>

<style lang="scss" scoped>
.dk-padd {
  padding-top: 15px;
  text-align: center;
}

// .littleBox {
//   width: 584px;
// }

.specialRad {
  font-size: 26px;
  line-height: 38px;
  margin-top: -15px;
  /* or 139% */

  text-align: center;

  /* PALETA/Primary */

  color: #001698;
}

.fonterPref {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 25px;
  text-align: center;
  color: #788591;
}

.Content.card-box-white {
  width: 584px;
  height: 552px;
}

.boxImgBGTop {
  position: absolute;
  top: 50px;
  left: 31px;
}

.radiologyText {
  font-weight: 600;
}

.size_icon {
  width: 173px;
}

.Card-img {
  width: 670px;
  margin-left: -18px;
  margin-top: -5px;
  object-position: center;
}

.serviceWeight {
  font-weight: 400;
}

.fonterAssigned {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

#video {
  width: 100%;
}

.fonterAssignedLabel {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
</style>
